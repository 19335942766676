import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { Grid, Margin, MoreBug, PageWrapper, Section } from "@components/atoms"
import { GridItem, Tags } from "@components/molecules"
import { Description } from "@components/molecules/GridItem"
import { TransitionMask } from "@components/organisms"
import { PageProps } from "@types"
import { palette } from "@theme"
import { useDisplayMode } from "@utils"

const WorkPage = ({
  data: {
    sanityPage: { title, description, gridItems },
  },
}: PageProps) => {
  const parsedTitle = "Franklyn: " + title
  const { displayMode } = useDisplayMode()
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
  ]
  return (
    <PageWrapper>
      <Helmet {...{ meta }} title={parsedTitle}>
        <title>{parsedTitle}</title>
      </Helmet>
      <Margin>
        <Section title="Work" noPadding>
          <Grid noPadding wideMobileRows>
            {gridItems
              .filter(p => !!p)
              .map(item => (
                <GridItem key={item._key} {...item}>
                  <>
                    <TitleWrapper>
                      <Title className="linkHighlight">
                        {item.post?.title}
                      </Title>
                      {/*<StyledTags tags={item.post?.tags} />*/}
                    </TitleWrapper>
                    <Description {...{ displayMode }}>
                      {item.post?.description}
                    </Description>
                  </>
                </GridItem>
              ))}
          </Grid>
        </Section>
      </Margin>
      <TransitionMask />
    </PageWrapper>
  )
}

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`

const Title = styled.h3`
  a {
    color: ${palette.white};
    transition: color 0.25s ease-in-out;
    &:hover {
      color: ${palette.lime};
    }
  }
  @media only screen and (min-width: 744px) {
    white-space: nowrap;
    padding-right: 20px;
  }
` // todo: why not use { ProjectTitle } from GridItem?

const StyledTags = styled(props => <Tags {...props} />)`
  @media only screen and (max-width: 743px) {
    display: none;
  }
`

export const query = graphql`
  query WorkPageQuery {
    sanityPage(slug: { current: { eq: "work" } }) {
      ...pageFields
    }
  }
`

export default WorkPage
